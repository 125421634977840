'use client'

import { useEffect, useState } from 'react'
import { createContext } from './context-utils'

export { LocaleContext, LocaleProvider, useLocale }

/** @typedef {string | string[] | Intl.Locale | Intl.Locale[]} LocaleContextValue */

const [LocaleContext, useLocale] = createContext('Locale', {
  /** @type {LocaleContextValue} */
  defaultValue: 'en-US',
})

/**
 * @param {{
 *   locale: LocaleContextValue
 * } & import('react').PropsWithChildren} props
 */
function LocaleProvider({ children, locale, ...props }) {
  const [value, setValue] = useState(locale)

  useEffect(() => {
    // We use an effect to ensure we're never setting locale to a null or undefined value
    if (locale) {
      setValue(locale)
    }
  }, [locale])

  return (
    <LocaleContext.Provider value={value} {...props}>
      {children}
    </LocaleContext.Provider>
  )
}
