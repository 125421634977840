export { isDate }

/**
 * Checks if the given value is an instance of Date, or a value that can be
 * coerced or parsed into a valid Date.
 *
 * @param {any} value
 * @returns {Date | undefined} a Date if the input can be coerced into a
 *  valid date, undefined otherwise
 */
function isDate(value) {
  const date = new Date(value)

  if (String(date) === INVALID || isNaN(Number(date))) {
    return
  }

  return date
}

const INVALID = String(new Date(undefined))
