export { isObject }

/**
 * Checks if the given value is an object.
 *
 * @param {any} value
 * @returns {true | false} true if `value` is an object; otherwise false
 */
function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]'
}
