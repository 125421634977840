'use client'

import React from 'react'
import { createContext } from './context-utils'

export { StyleContext, StyleProvider, useStyle }

/**
 * @typedef {{
 *   style: React.CSSProperties
 * }} StyleContextValue
 */

const [StyleContext, useStyle] = createContext('Style', {
  /** @type {StyleContextValue} */
  defaultValue: {
    style: {},
  },
})

/** @param {StyleContextValue & import('react').PropsWithChildren} props */
function StyleProvider({ children, style, ...props }) {
  return (
    <StyleContext.Provider value={style} {...props}>
      {children}
    </StyleContext.Provider>
  )
}
