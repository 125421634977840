'use client'

import * as DialogPrimitive from '@radix-ui/react-dialog'
import { forwardRef } from 'react'
import { Theme } from '@radix-ui/themes'
import { mergeProps, withProps } from '../component-utils.jsx'
import styles from './sheet.module.css'

export {
  Sheet,
  Sheet as Root,
  SheetPortal,
  SheetPortal as Portal,
  SheetOverlay,
  SheetOverlay as Overlay,
  SheetTrigger,
  SheetTrigger as Trigger,
  SheetClose,
  SheetClose as Close,
  SheetContent,
  SheetContent as Content,
  SheetHeader,
  SheetHeader as Header,
  SheetFooter,
  SheetFooter as Footer,
  SheetTitle,
  SheetTitle as Title,
  SheetDescription,
  SheetDescription as Description,
}

const Sheet = withProps(DialogPrimitive.Root, {
  modal: true,
})

const SheetTrigger = DialogPrimitive.Trigger

const SheetClose = DialogPrimitive.Close

const SheetPortal = DialogPrimitive.Portal

const SheetOverlay = withProps(DialogPrimitive.Overlay, {
  className: styles.overlay,
})

/**
 * @typedef {import('react').ComponentPropsWithoutRef<
 *   typeof DialogPrimitive.Content
 * > & { side: 'top' | 'bottom' | 'left' | 'right' }} SheetContentProps
 */

const SheetContent = forwardRef(
  /** @param {SheetContentProps} props */
  ({ side = 'left', children, container, ...props }, ref) => (
    <SheetPortal container={container}>
      <Theme asChild>
        <SheetOverlay>
          <DialogPrimitive.Content
            {...mergeProps(props, {
              className: [styles.content, styles[`content-side-${side}`]],
            })}
            ref={ref}
          >
            {children}
          </DialogPrimitive.Content>
        </SheetOverlay>
      </Theme>
    </SheetPortal>
  ),
)
SheetContent.displayName = DialogPrimitive.Content.displayName

const SheetHeader = withProps(
  'div',
  {
    className: styles.header,
  },
  'SheetHeader',
)

const SheetFooter = withProps(
  'div',
  {
    className: styles.footer,
  },
  'SheetHeader',
)

const SheetTitle = withProps(DialogPrimitive.Title, {
  className: styles.title,
})

const SheetDescription = withProps(DialogPrimitive.Title, {
  className: styles.description,
})
