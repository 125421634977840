export { isNil }

/**
 * Checks that the given value is null, undefined, or NaN.
 *
 * @param {any} value
 * @returns {value} true if the given value is null, undefined or NaN; returns false otherwise
 */
function isNil(value) {
  return value === null || value === undefined || Number.isNaN(value)
}
