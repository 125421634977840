export { empty, EmptyProtocol }

/**
 * Symbol used to mark a function as implementing the empty protocol, meaning
 * the function returns the empty variant of a certain type.
 *
 * The function may take a single parameter, being an example of the type of
 * value the function supports creating an empty version of. If the function
 * does not recognize the type of the value, it must return undefined.
 */
const EmptyProtocol = Symbol('empty protocol')

/**
 * @typedef {Int8Array
 *   | Uint8Array
 *   | Uint8ClampedArray
 *   | Int16Array
 *   | Uint16Array
 *   | Int32Array
 *   | Uint32Array
 *   | Float32Array
 *   | Float64Array} TypedArray
 */

/**
 * Returns the empty variant of the given input value.
 *
 * @param {any} value
 * @returns {String | Object | Array | Function | TypedArray} An empty variant
 */
function empty(value) {
  if (value?.[EmptyProtocol]) {
    // Value implements the empty protocol, so lets just return that
    return value[EmptyProtocol]()
  }

  // Object.prototype.toString returns a string like '[object Type]', so we
  // extract the type with some string manipulation
  const [, type] = Object.prototype.toString.call(value).slice(1, -1).split(' ')
  const makeEmpty = EmptyImplementation[type]

  return makeEmpty?.(value)
}

const EmptyImplementation = {
  String: {
    [EmptyProtocol]: () => '',
  },
  Object: {
    [EmptyProtocol]: () => ({}),
  },
  Array: {
    [EmptyProtocol]: () => [],
  },
  Function: {
    [EmptyProtocol]: () => () => {},
  },

  // Ignore TS errors about no overload using the empty string as an array like.
  Int8Array: { [EmptyProtocol]: () => Int8Array.from([]) },
  Uint8Array: { [EmptyProtocol]: () => Uint8Array.from([]) },
  Uint8ClampedArray: { [EmptyProtocol]: () => Uint8ClampedArray.from([]) },
  Int16Array: { [EmptyProtocol]: () => Int16Array.from([]) },
  Uint16Array: { [EmptyProtocol]: () => Uint16Array.from([]) },
  Int32Array: { [EmptyProtocol]: () => Int32Array.from([]) },
  Uint32Array: { [EmptyProtocol]: () => Uint32Array.from([]) },
  Float32Array: { [EmptyProtocol]: () => Float32Array.from([]) },
  Float64Array: { [EmptyProtocol]: () => Float64Array.from([]) },
  BigInt64Array: { [EmptyProtocol]: () => BigInt64Array.from([]) },
  BigUint64Array: { [EmptyProtocol]: () => BigUint64Array.from([]) },
}
