import { reject } from './reject'
import { isNil } from './is-nil'

export { pack }

/**
 * Creates a new array or object using the values from the given iterable,
 * but rejects any values that are nil.
 *
 * @see nil
 *
 * @param {Iterable | Object} iterable
 * @returns {Array | Object} an array or object with only non-nil values
 */
function pack(iterable) {
  return reject((value) => isNil(value), iterable)
}
