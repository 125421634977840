import Image from 'next/image'

export { Logo }

function Logo({ width = 120, height = 36, ...props }) {
  return (
    <Image
      alt="Synqly Logo"
      height={height}
      priority
      src="/images/synqly-logo.png"
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      width={width}
      {...props}
    />
  )
}
