export { isFun }

/**
 * Checks if the given value is a function.
 *
 * @param {any} value
 * @returns {true | false} true if `value` is a function; otherwise false
 */
function isFun(value) {
  return typeof value === 'function'
}
