export { groupBy }

/**
 * Turns the given list of items into an object where each key points to an
 * array of items for that key, as specified by the given key function.
 *
 * @param {Iterable<any>} list - an iterable object, such as an array
 * @param {(item: any) => string} key - a function to generate a key,
 *  given an individual item
 *
 * @returns {Groups} an object where they keys are those generated by
 *  `key`, and the value is an array including all items for that key.
 *
 * @typedef {Record<String, Array.<any>>} Groups
 */
function groupBy(key, list) {
  /** @type{Groups} */
  const items = {}

  for (const item of list) {
    const k = key(item)

    if (items[k]) {
      items[k] = [...items[k], item]
    } else {
      items[k] = [item]
    }
  }

  return items
}
