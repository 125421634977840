'use client'

import { useEffect, useState } from 'react'
import { createContext } from './context-utils'

export { EnvContext, EnvProvider, useEnv }

/**
 * @typedef {{
 *   [key: string]: string | undefined
 * }} EnvContextValue
 */

const [EnvContext, useEnv] = createContext('Env', {
  /** @type {EnvContextValue} */
  defaultValue: {},
})

/** @param {{ env: EnvContextValue } & import('react').PropsWithChildren} props */
function EnvProvider({ env, children, ...props }) {
  const [value, setValue] = useState(env)

  useEffect(() => {
    // We use an effect to ensure we're never setting env to a null or undefined value
    if (env) {
      setValue(env)
    }
  }, [env])

  return (
    <EnvContext.Provider value={value} {...props}>
      {children}
    </EnvContext.Provider>
  )
}
