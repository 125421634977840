import { isFun } from './is-fun'

export { valueOf }

/**
 * @template T
 * @typedef {(...args: any[]) => T} Callable
 */

/**
 * Returns the value of the given item.
 *
 * @remarks
 *   If the given argument is a function, it will be called with any supplementary
 *   args given. Otherwise, the argument's `valueOf` function will be called if
 *   it has one, and if not the argument is returned as-is.
 * @template T
 * @template U
 * @param {T | Callable<U>} item The item to use when resolving a value, will be
 *   called if it's a function, or if it has a `valueOf` function that will be
 *   called with any supplementary args given
 * @param {any[]} args Supplementary args to use when calling `item` if it's a
 *   function, or when calling `valueOf` if `item` has such a function
 * @returns {T | U} The value of the given argument
 */
function valueOf(item, ...args) {
  if (isFun(item)) {
    return /** @type {Callable<T>} */ (item)(...args)
  } else if (isFun(item?.valueOf)) {
    return /** @type {T} */ (item.valueOf())
  }

  return /** @type {T} */ (item)
}
