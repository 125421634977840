import { Cross1Icon, HamburgerMenuIcon } from '@radix-ui/react-icons'
import { signOut as deauthorize, useSession } from 'next-auth/react'
import Head from 'next/head'
import { groupBy } from '@synqly/fun'
import { Text, Heading, Box, Slot, Button, Inset } from '@radix-ui/themes'
import { getResponsiveClassNames } from '@radix-ui/themes/helpers'
import { Fragment } from 'react'
import * as NavigationMenu from '../../components/navigation-menu'
import { Container } from '../../components/container'
import { Logo } from '../../components/logo'
import * as Sheet from '../../components/sheet'
import { mergeProps, toFlatArray } from '../../component-utils'
import { StyleProvider, useStyle } from '../../context/style'
import styles from './page-layout.module.css'
import { ProfileMenu } from './profile-menu'

export { Layout, Content, Section, Aside }

/**
 * @param {{
 *   metadata?: import('next').Metadata
 *   pageProps?: any
 *   children?: import('react').ReactNode
 * }} props
 */
function Layout({ metadata = {}, children }) {
  const { data: session } = useSession({ required: true })

  if (!session) {
    return null
  }

  const secondary = (metadata.other?.links ?? []).filter(({ rel }) =>
    rel.startsWith('synqly:nav:secondary'),
  )

  const grouped = groupBy(({ rel }) => rel, secondary)

  return (
    <Container className={styles.page}>
      <div className={styles.pageBody}>
        <Header metadata={metadata} />
        <PrimaryNavigation metadata={metadata} variant="sheet" />
        <PrimaryNavigation metadata={metadata} variant="normal" />

        {secondary.length > 0 && (
          <Navigation links={grouped} variant="secondary" />
        )}
        <main className={styles.content}>{children}</main>
      </div>
    </Container>
  )
}

/**
 * @param {{
 *   metadata: import('next').Metadata
 *   variant: 'sheet' | 'normal'
 * }} props
 */
function PrimaryNavigation({ metadata = {}, variant = 'normal' }) {
  const primary = (metadata.other?.links ?? []).filter(({ rel }) =>
    rel.startsWith('synqly:nav:primary'),
  )

  const grouped = groupBy(({ rel }) => rel, primary)
  const nav = (
    <Navigation
      links={grouped}
      variant={['primary', variant].filter(Boolean).join('-')}
    />
  )

  if (variant === 'sheet') {
    return (
      <Sheet.Root>
        <Sheet.Trigger asChild className={styles.navPrimarySheetTrigger}>
          <Button color="gray" variant="ghost">
            <HamburgerMenuIcon />
            <Text className={styles.navPrimaryTriggerText}>Menu</Text>
          </Button>
        </Sheet.Trigger>
        <Sheet.Content
          asChild
          className={styles.navPrimarySheetContent}
          side="left"
        >
          <Inset>
            <Sheet.Close asChild className={styles.navPrimarySheetClose}>
              <Button color="gray" variant="ghost">
                <Cross1Icon />
                Close
              </Button>
            </Sheet.Close>
            {nav}
          </Inset>
        </Sheet.Content>
      </Sheet.Root>
    )
  }

  return nav
}

function Navigation({ links, variant = 'primary' }) {
  const orientation = variant.startsWith('primary') ? 'vertical' : 'horizontal'

  const SheetCloseTrigger =
    variant === 'primary-sheet'
      ? (props) => <Sheet.Close asChild {...props} />
      : ({ children }) => children

  return (
    <NavigationMenu.Root
      className={styles[`nav-variant-${variant}`]}
      orientation={orientation}
      variant={variant.startsWith('primary') ? 'primary' : 'secondary'}
    >
      <NavigationMenu.List>
        {Object.entries(links).map(([group, links]) => (
          <NavigationMenu.Group key={group}>
            {links.map(({ disabled, ...link }) => (
              <NavigationMenu.Item
                disabled={disabled}
                key={`${group}: ${link.href}`}
              >
                <SheetCloseTrigger>
                  {link.href ? (
                    <NavigationMenu.Link href={link.href} rel={link.rel}>
                      {link.title}
                    </NavigationMenu.Link>
                  ) : (
                    link.title
                  )}
                </SheetCloseTrigger>
              </NavigationMenu.Item>
            ))}
          </NavigationMenu.Group>
        ))}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  )
}

/**
 * @param {{
 *   metadata: import('next').Metadata
 * }} props
 */
function Header({ metadata = {} }) {
  const { data: session } = useSession({ required: true })

  const { user, organization } = session

  const orgName = organization.fullname || organization.name
  const title = metadata.title?.toString()
  const crumbs = [orgName, metadata.other?.crumbs]
    .flat()
    .filter(Boolean)
    .join(' • ')

  return (
    <>
      <Head>
        <title>{[orgName, title].filter(Boolean).join(' - ')}</title>
      </Head>
      <Logo className={styles.logo} />
      <Box className={styles.header}>
        <Text className={styles.crumbs} color="gray">
          {crumbs !== title && crumbs}
        </Text>
        <Heading size="5">
          {title}

          {!!metadata.other?.meta && (
            <Text className={styles.headerMetaTitle}>
              {metadata.other.meta}
            </Text>
          )}
        </Heading>
      </Box>
      <ProfileMenu
        onSignOut={() => signOut({ session })}
        organization={organization}
        user={user}
      />
    </>
  )
}

function Content({ children }) {
  const content = toFlatArray(children).reduce(
    (content, node, order) => {
      const variant = node.type?.[$] ?? ContentBlockType.Main
      const Block = node.type?.[$] ? Fragment : Section

      content[variant].push(
        <StyleProvider
          key={node.key}
          style={{
            '--content-order': order,
          }}
        >
          <Block key={node.key}>{node}</Block>
        </StyleProvider>,
      )

      return content
    },
    {
      [ContentBlockType.Main]: [],
      [ContentBlockType.Aside]: [],
    },
  )

  return (
    <>
      <div className={styles.contentColumnMain}>{content.main}</div>
      <div className={styles.contentColumnAside}>{content.aside}</div>
    </>
  )
}

/** @enum {'main' | 'aside'} */
const ContentBlockType = /** @type {const} */ ({
  Main: 'main',
  Aside: 'aside',
})

const $ = process.env.buildId
Object.defineProperties(Aside, {
  [$]: {
    value: ContentBlockType.Aside,
  },
})

Object.defineProperties(Section, {
  [$]: {
    value: ContentBlockType.Main,
  },
})

/**
 * @typedef {{
 *   asChild?: boolean
 *   size?: import('@radix-ui/themes').Responsive<'1' | '2'>
 *   order?: number
 *   className?: string
 * } & import('react').PropsWithChildren} ContentBlockProps
 *
 * @param {ContentBlockProps} props
 */
function ContentBlock({ asChild, size = '2', order, ...props }) {
  const { '--content-order': contentOrder } = useStyle()
  const Comp = asChild ? Slot : 'div'
  return (
    <Comp
      {...mergeProps(props, {
        style: {
          '--content-order': order ?? contentOrder,
        },
        className: [
          styles.contentBlock,
          getResponsiveClassNames({
            allowArbitraryValues: true,
            className: 'rt-r-size',
            value: size,
            propValues: ['1', '2'],
          }),
        ],
      })}
    />
  )
}

/** @param {Omit<ContentBlockProps, 'asChild'>} props */
function Section({ children, ...props }) {
  return (
    <ContentBlock {...props} asChild className={styles.contentBlock_main}>
      <section>{children}</section>
    </ContentBlock>
  )
}

/** @param {Omit<ContentBlockProps, 'asChild'>} props */
function Aside({ children, ...props }) {
  return (
    <ContentBlock {...props} asChild className={styles.contentBlock_main}>
      <aside>{children}</aside>
    </ContentBlock>
  )
}

function signOut({ session, error, callbackUrl } = {}) {
  const searchParams = new URLSearchParams()

  const { organization } = session ?? {}
  if (organization) {
    searchParams.set('organizationId', organization.name ?? organization.id)
  }

  if (error) {
    searchParams.set('error', error.message ?? error)
  }

  if (callbackUrl) {
    searchParams.set('callbackUrl', callbackUrl)
  }

  const search = searchParams.size ? `?${searchParams}` : ''

  deauthorize({
    callbackUrl: `/auth/signin${search}`,
  })

  return null
}
