export { isIterable }

/**
 * Checks if the given value implements the iterator protocol.
 *
 * @param {any} value
 * @returns {true | false} true if `value` is an iterable; otherwise false
 */
function isIterable(value) {
  return Boolean(value?.[Symbol.iterator])
}
