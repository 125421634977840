export { id }

/**
 * The identity function, returns its given argument.
 *
 * @param {any} value
 * @returns {value} the given value
 */
function id(value) {
  return value
}
