import { App, getInitialProps } from '@synqly/ui/pages/_app'
import {
  DashboardIcon,
  GearIcon,
  InfoCircledIcon,
  Link1Icon,
  ListBulletIcon,
  TargetIcon,
} from '@synqly/ui/radix/icons'
import { Flex, Text, Switch } from '@synqly/ui/radix/themes'
import '@synqly/ui/styles/theme-management.css'
import Error from 'next/error'
import { LinkContext } from '@synqly/ui/components/link'
import { useRouter } from 'next/router'
import { Layout as DefaultLayout } from '@synqly/ui/layouts/management/page-layout'
import { Container } from '@synqly/ui/components/container'
import { useEnv } from '@synqly/ui/context/env'
import { isValidEnvironment } from '@/util/is-valid-environment.js'
import styles from './management-app.module.css'

export { ManagementApp as default }

function ManagementApp(props) {
  const router = useRouter()
  const { env } = router.query

  if (env && !isValidEnvironment(env)) {
    return <Error statusCode={404} />
  }

  const metadata = {
    ...initialMetadata,
    other: {
      links: [
        ...initialMetadata.other.links,
        {
          rel: 'synqly:nav:primary:/[env]',
          title: <EnvironmentSwitch environment={env} />,
        },
      ],
    },
  }

  return (
    <App
      defaultLayout={ManagementLayout}
      defaultMetadata={metadata}
      {...props}
    />
  )
}

function ManagementLayout(props) {
  const router = useRouter()
  const { env } = router.query
  const { NEXT_PUBLIC_APP_URL: origin } = useEnv()

  return (
    <LinkContext.Provider value={{ basePath: `/${env}`, origin }}>
      <Flex direction="column">
        {env === 'test' && (
          <Container className={styles.environmentBanner}>
            <Flex gap="4" justify="center">
              <Text weight="bold">Test mode</Text>
              <Text>You’re now working only with test accounts.</Text>
            </Flex>
          </Container>
        )}
        <DefaultLayout {...props} />
      </Flex>
    </LinkContext.Provider>
  )
}

function EnvironmentSwitch({ environment }) {
  const router = useRouter()

  return (
    <Flex className={styles.environmentSwitch}>
      <Text>Test mode</Text>
      <Switch
        defaultChecked={environment === 'test'}
        onCheckedChange={(test) =>
          router.push({
            pathname: router.pathname,
            query: {
              ...router.query,
              env: test ? 'test' : 'prod',
            },
          })
        }
      />
    </Flex>
  )
}

ManagementApp.getInitialProps = getInitialProps

/**
 * @typedef {Object} MetadataLink
 * @property {string | function} href
 * @property {string} rel
 * @property {string | JSX.Element} title
 */

/**
 * @typedef {Object} Metadata
 * @property {Object} other
 * @property {MetadataLink[]} other.links
 */

/** @type {Metadata} */
const initialMetadata = {
  other: {
    links: [
      {
        href: '/',
        rel: 'synqly:nav:primary:/dash',
        title: (
          <>
            <DashboardIcon />
            Dashboard
          </>
        ),
      },
      {
        href: '/issues',
        rel: 'synqly:nav:primary:/data',
        title: (
          <>
            <InfoCircledIcon />
            Issues
          </>
        ),
      },
      {
        href: '/accounts',
        rel: 'synqly:nav:primary:/data',
        title: (
          <>
            <ListBulletIcon />
            Accounts
          </>
        ),
      },
      {
        href: '/integration-points',
        rel: 'synqly:nav:primary:/data',
        title: (
          <>
            <TargetIcon />
            Integration Points
          </>
        ),
      },
      {
        href: '/integrations',
        rel: 'synqly:nav:primary:/data',
        title: (
          <>
            <Link1Icon />
            All Integrations
          </>
        ),
      },
      {
        href: '/settings',
        rel: 'synqly:nav:primary:/settings',
        title: (
          <>
            <GearIcon />
            Settings
          </>
        ),
      },
      {
        href: 'https://docs.synqly.com/reference/api-overview',
        rel: 'synqly:nav:primary:/docs',
        title: 'Getting started',
      },
      {
        href: 'https://docs.synqly.com',
        rel: 'synqly:nav:primary:/docs',
        title: 'Documentation',
      },
    ],
  },
}
