import { createContext, useContext } from 'react'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { useRouter } from 'next/router.js'
import { mergeProps, withProps } from '../component-utils.jsx'
import { Link as Anchor } from './link.jsx'
import styles from './navigation-menu.module.css'

export * from '@radix-ui/react-navigation-menu'
export { Root, List, Link, Group, Item, Indicator }

const NavigationContext = createContext({
  variant: 'primary',
})

/**
 * @param {import('@radix-ui/react-navigation-menu').NavigationMenuProps & {
 *   variant: 'primary' | 'secondary'
 * }} props
 */
function Root({ orientation, variant = 'primary', ...props }) {
  const context = {
    variant,
  }

  return (
    <NavigationContext.Provider value={context}>
      <NavigationMenu.Root
        {...mergeProps(props, {
          orientation,
          className: [styles.root, styles[`root-${variant}`]],
        })}
      />
    </NavigationContext.Provider>
  )
}

const List = withProps(NavigationMenu.List, {
  className: styles.list,
})

function Group(props) {
  return (
    <div
      {...mergeProps(props, {
        className: styles.group,
      })}
    />
  )
}

/**
 * @param {import('@radix-ui/react-navigation-menu').NavigationMenuItemProps & {
 *     disabled?: boolean
 *   }} props
 */
function Item({ disabled = undefined, ...props }) {
  return (
    <NavigationMenu.Item
      aria-disabled={disabled}
      {...mergeProps(props, {
        className: styles.item,
      })}
    />
  )
}

/** @param {import('@radix-ui/react-navigation-menu').NavigationMenuLinkProps} props */
function Link({ href, children, ...props }) {
  const { asPath } = useRouter()
  const { variant } = useContext(NavigationContext)
  // TODO: Extract this logic to set the `active` flag to the respective
  // apps using this component. The way this is done ties it both to the
  // NextJS router and the management app as well. The only reason this
  // doesn't break anything else is because management is the only app
  // using this URL pattern right now.
  const pathWithoutEnv = asPath.replace(/\/(?:test|prod)/, '')
  const herfWithoutEnv = href.replace(/\/(?:test|prod)/, '')

  return (
    <NavigationMenu.Link
      active={
        pathWithoutEnv === herfWithoutEnv ||
        (herfWithoutEnv.length > 1 && pathWithoutEnv.startsWith(herfWithoutEnv))
      }
      {...mergeProps(props, {
        className: [styles.link, styles[`link-${variant}`]],
      })}
      asChild
    >
      <Anchor asChild href={href}>
        <>{children}</>
      </Anchor>
    </NavigationMenu.Link>
  )
}

const Indicator = withProps(NavigationMenu.Indicator, {
  className: styles.indicator,
})
