import { AvatarIcon, ExitIcon } from '@radix-ui/react-icons'
import { Avatar, DropdownMenu, Text, Button } from '@radix-ui/themes'
import { Link } from '../../components/link'
import styles from './page-layout.module.css'

export function ProfileMenu({ user, onSignOut, ...props }) {
  if (!user) {
    return null
  }

  const userName = user.fullname || user.name

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className={styles.profileTrigger}>
        <Button color="gray" variant="ghost">
          <Text className={styles.profileName}>{userName}</Text>
          <Avatar
            fallback={<AvatarIcon />}
            radius="full"
            src={user.picture}
            style={{ '--avatar-size': 'auto' }}
          />
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        align="end"
        className={styles.profileContent}
        side="bottom"
        {...props}
      >
        <Link asChild href={`/settings/members/${user.id}`}>
          <DropdownMenu.Item className={styles.profileItem}>
            Profile
          </DropdownMenu.Item>
        </Link>

        <DropdownMenu.Separator />

        <DropdownMenu.Item className={styles.profileItem} onSelect={onSignOut}>
          Sign out
          <ExitIcon />
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
