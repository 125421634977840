import { isObject } from './is-object'
import { isFun } from './is-fun'
import { isIterable } from './is-iterable'

export { reject }

/**
 * Opposite of filter, rejects values from the given iterable if they match
 * the given predicate.
 *
 * @param {(value: any) => true|false} predicate
 * @param {Iterable | Object} iterable
 * @returns {Array | Object} an array or object only containing those values
 *  not rejected by the predicate
 * @throws when given invalid parameters
 */
function reject(predicate, iterable) {
  if (!isFun(predicate)) {
    throw new TypeError('predicate must be a function')
  }

  if (isIterable(iterable)) {
    return rejectIterableValues(predicate, iterable)
  } else if (isObject(iterable)) {
    return rejectObjectValues(predicate, iterable)
  }

  throw new TypeError(
    'iterable must implement the iterator protocol or be an enumerable object',
  )
}

/**
 * @param {(value: any) => true|false} predicate
 * @param {Iterable} iterable
 * @returns {Array} an array only containing those values not rejected by the predicate
 */
function rejectIterableValues(predicate, iterable) {
  const result = []

  for (const value of iterable) {
    if (!predicate(value)) {
      result.push(value)
    }
  }

  return result
}

/**
 * @param {(value: any) => true|false} predicate
 * @param {Object} enumerable
 * @returns {Object} an object only containing those values not rejected by the predicate
 */
function rejectObjectValues(predicate, enumerable) {
  const result = {}

  for (const [key, value] of Object.entries(enumerable)) {
    if (!predicate(value)) {
      result[key] = value
    }
  }

  return result
}
